import $ from 'jquery'

const Munki = (($) => {

//Smooth Scrolling For Internal Page Links
$(function() {
  $('a[href*=\\#spy]:not([href=\\#])').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
    $('html,body').animate({
      scrollTop: target.offset().top - 60
    }, 1000);
    return false;
    }
  }
  });
});


  window.addEventListener("load", function(){
  var p;

    window.cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#aa0000",
          "text": "#ffdddd"
        },
        "button": {
          "background": "#ff0000"
        }
      },
      "position": "bottom",
      "type": "opt-in",
      "content": {
      },
      onPopupOpen: function() {
        //console.log('<em>onPopupOpen()</em> called');
      },
      onPopupClose: function() {
        //console.log('<em>onPopupClose()</em> called');
      },
      onInitialise: function (status) {
        //console.log('<em>onInitialise()</em> called with status <em>'+status+'</em>');
      },
      onStatusChange: function(status) {
        //console.log('<em>onStatusChange()</em> called with status <em>'+status+'</em>');
        var choice = this.hasConsented() ?
        'enable cookies' : 'disable cookies';
        console.log(choice);
        if(choice === 'disable cookies'){
          //console.log('denied');
          var ajaxurl = 'co/deny-munkiboy.php',
          data =  {'action': 'deny'};
          $.post(ajaxurl, data, function (response) {
              // Response div goes here.
          });
        } else {
          //console.log('allowed');
          var ajaxurl = 'co/undelete-munkiboy.php',
          data =  {'action': 'consent'};
          $.post(ajaxurl, data, function (response) {
              // Response div goes here.
          });
        }
        window.location.reload(false);
      },
    onRevokeChoice: function() {
      //console.log('<em>onRevokeChoice()</em> called');
      delete_cookie('_gat');
      delete_cookie('_ga');
      delete_cookie('_gid');
      var ajaxurl = 'co/delete-munkiboy.php',
      data =  {'action': 'revoke'};
      $.post(ajaxurl, data, function (response) {
          // Response div goes here.
      });
      window.location.reload(false); 
    },
  }, function (popup) {
    p = popup;
  }, function (err) {
    console.error(err);
  });

$( ".btn-open" ).click(function(e) {
  //console.log("Calling <em>open()</em>");
  p.open();
});

});
  var delete_cookie = function(name) {
      document.cookie = name + '=; Path=/; Domain=.munkiboy.co.uk; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };





  return Munki

})($)

export default Munki
